@import './mixins.sass'

@font-face
    font-family: 'icomoon'
    src: url('~epic-fortnite-styles/fonts/fortnite-icons/icomoon.eot?xq2mnn')
    src: url('~epic-fortnite-styles/fonts/fortnite-icons/icomoon.eot?xq2mnn#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/fortnite-icons/icomoon.ttf?xq2mnn') format('truetype'), url('~epic-fortnite-styles/fonts/fortnite-icons/icomoon.woff?xq2mnn') format('woff'), url('~epic-fortnite-styles/fonts/fortnite-icons/icomoon.svg?xq2mnn#icomoon') format('svg')
    font-weight: normal
    font-style: normal
    unicode-range: U+20bd, U+e0??, U+e6??, U+e9??, U+ea??, U+e8??

[class^="icon-"], [class*=" icon-"]
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important
    speak: none
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none

    /* Enable Ligatures ================ */
    letter-spacing: 0
    -webkit-font-feature-settings: "liga"
    -moz-font-feature-settings: "liga=1"
    -moz-font-feature-settings: "liga"
    -ms-font-feature-settings: "liga" 1
    font-feature-settings: "liga"
    -webkit-font-variant-ligatures: discretionary-ligatures
    font-variant-ligatures: discretionary-ligatures

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale


/* Fortnite Heroes */
.icon-ninja:before
    content: "\e909"
.icon-outlander:before
    content: "\e90a"
.icon-commando:before,
.icon-soldier:before
    content: "\e902"
.icon-constructor:before
    content: "\e903"
/* Fortnite SFS Icon */
.icon-sfs-icon:before
    content: "\e912"
.icon-packs:before
    content: "\e917"
.icon-arrow
    font-size: 1.2em
    &:before
        content: "\e911"
        font-size: 0.8em
.icon-arrow-right:before
    content: "\e911"
.icon-arrow-left
    &:before
        content: "\e911"
    transform: rotate(-180deg)
    display: block
.icon-menu:before
  content: "\e9bd"

/* Fortnite Icons */
.icon-magnifyGlass:before
    content: "\e915"
.icon-clock:before
    content: "\e90e"
.icon-x:before
    content: "\e913"
.icon-play-triangle:before
    content: "\ea1c"
.icon-badge:before
    content: "\e900"
.icon-check:before
    content: "\e901"
.icon-down-arrow:before
    content: "\e905"
.icon-up-arrow
    transform: rotate(-180deg)
    &:before
        content: "\e905"
.icon-left-arrow:before
    content: "\e906"
.icon-lock:before
    content: "\e908"
.icon-reverse-left-arrow:before
    content: "\e90b"
.icon-reverse-right-arrow:before
    content: "\e90f"
.icon-right-arrow:before
    content: "\e911"
.icon-toxic:before
    content: "\e919"
.icon-close:before
    content: "\e914"
.icon-play:before
    content: "\e605"
.icon-refresh:before
    content: "\e800"
.icon-grid:before
    content: "\e90d"
.icon-link-external:before
    content: "\e907"
.icon-warning:before
    content: "\ea07"
.icon-notification:before
    content: "\ea08"
.icon-plus:before
    content: "\ea0a"
.icon-tv:before
  content: "\e924"
.icon-location:before
  content: "\e947"
.icon-full-arrow
    font-size: 1.2em
    &:before
        content: "\e92c"
        font-size: 0.8em

/* Social Icons */
.icon-facebook:before
    content: "\ea90"
.icon-vk:before
    content: "\ea98"
.icon-reddit:before
    content: "\e916"
.icon-vk-noBox:before
    content: "\e91a"
.icon-twitch:before
    content: "\e904"
.icon-google-plus:before,
.icon-google:before
    content: "\e910"
.icon-youtube:before
    content: "\e90c"
.icon-facebook-circle:before
    content: "\e918"
.icon-instagram:before
    content: "\e91b"
.icon-twitter:before
    content: "\e91c"
.icon-mixer:before
    content: "\e922"
.icon-github:before
    content: "\e929"
.icon-steam:before
    content: "\eaad"

/* Ruble Icon */
.icon-ruble:before
    content: "\20bd"
.icon-pc:before,
.icon-pcMac:before
    content: "\e91d"
.icon-ps4:before,
.icon-psn:before
    content: "\e91e"
.icon-xbl:before,
.icon-xbox:before
    content: "\e91f"
.icon-pc_mobile:before
  content: "\e920"
.icon-mobile:before
  content: "\e921"
.icon-nintendo:before,
.icon-switch:before
  content: "\e923"
.icon-iOS:before
  content: "\e925"
.icon-android:before
  content: "\e926"
.icon-mac-os:before
  content: "\e927"
.icon-windows:before
  content: "\e928"
.icon-exclamation-circle:before
  content: "\e92a"
.icon-ticket:before
  content: "\e92b"

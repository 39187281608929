//
//
//== Mixins ==*/
//
=phone-landscape
  @media only screen and (orientation: landscape) and (min-aspect-ratio: 1/1)
    @content

=ellipsis()
    white-space: pre
    text-overflow: ellipsis
    overflow: hidden

=vertical-align($position: relative)
    position: $position
    top: 50%
    transform: translateY(-50%)

=respond-to($media)
    @if $media == xs
        //select xs devices and up
        @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)
            @content

    @if $media == xl
        //select lg devices and up
        @media only screen and (min-width: $screen-xl-min)
            @content

    @if $media == xxl
        //select lg devices and up
        @media only screen and (min-width: $screen-xxl-min)
            @content

=respond-min-width($media)
    @if $media == xs
        //select xs devices and up
        @media only screen and (min-width: $screen-xs-min)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (min-width: $screen-sm-min)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (min-width: $screen-md-min)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (min-width: $screen-lg-min)
            @content

    @if $media == xl
        //select lg devices and up
        @media only screen and (min-width: $screen-xl-min)
            @content

    @if $media == xxl
        //select lg devices and up
        @media only screen and (min-width: $screen-xxl-min)
            @content

=respond-max-width($media)
    @if $media == min
        //select xs devices and up
        @media only screen and (max-width: $screen-xs-min)
            @content

    @if $media == xs
        //select xs devices and up
        @media only screen and (max-width: $screen-xs-max)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (max-width: $screen-sm-max)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (max-width: $screen-md-max)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (max-width: $screen-lg-max)
            @content
    @if $media == xl
        //select lg devices and up
        @media only screen and (min-width: $screen-xl-min)
            @content

    @if $media == xxl
        //select lg devices and up
        @media only screen and (min-width: $screen-xxl-min)
            @content

=respond-handheld-min-width($media, $orientation, $ratio: 1)
    @if $orientation != null and $media == xs
        @media only screen and (device-min-width: $screen-xs-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == sm
        @media only screen and (device-min-width: $screen-sm-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == md
        @media only screen and (device-min-width: $screen-md-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == lg
        @media only screen and (device-min-width: $screen-lg-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == xl
        @media only screen and (device-min-width: $screen-lg-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == xxl
        @media only screen and (device-min-width: $screen-lg-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content

=respond-handheld-max-width($media, $orientation, $ratio: 1)
    @if $orientation != null and $media == xs
        @media only screen and (device-max-width: $screen-xs-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == sm
        @media only screen and (device-max-width: $screen-sm-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == md
        @media only screen and (device-max-width: $screen-md-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == lg
        @media only screen and (device-max-width: $screen-lg-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == xl
        @media only screen and (device-max-width: $screen-xl-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
    @if $orientation != null and $media == xxl
        @media only screen and (device-max-width: $screen-xxl-min) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $ratio)
            @content
// @media only screen and (max-width: $screen-xs-min) and (orientation: $orientation)  { @content }

=keyframes($animation-name)
    @-webkit-keyframes #{$animation-name}
        @content

    @-moz-keyframes #{$animation-name}
        @content

    @-ms-keyframes #{$animation-name}
        @content

    @keyframes #{$animation-name}
        @content

=clearfix
    &:before, &:after
        content: " "
        display: table
    &:after
        clear: both

=gradient($startColor: #555, $endColor: #333, $startPercent: 0%, $endPercent: 100%)
    background: rgba($startColor,1)
    background: linear-gradient(to right, rgba($startColor,1) $startPercent, rgba($endColor,1) $endPercent)

//
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 568px !default
$screen-xs-min: $screen-xs !default
$screen-phone: $screen-xs-min !default

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default
$screen-sm-min: $screen-sm !default
$screen-tablet: $screen-sm-min !default

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default
$screen-md-min: $screen-md !default
$screen-desktop: $screen-md-min !default

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default
$screen-lg-min: $screen-lg !default
$screen-lg-desktop: $screen-lg-min !default

$screen-xl: 1440px !default
$screen-xl-min: $screen-xl !default
$screen-xl-desktop: $screen-xl-min !default

$screen-xxl: 1600px !default
$screen-xxl-min: $screen-xl !default
$screen-xxl-desktop: $screen-xl-min !default

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: $screen-sm-min - 1 !default
$screen-sm-max: $screen-md-min - 1 !default
$screen-md-max: $screen-lg-min - 1 !default
$screen-lg-max: $screen-xl-min - 1 !default
$screen-xl-max: $screen-xxl-min - 1 !default
$screen-xxl-max: $screen-xxl-min - 1 !default

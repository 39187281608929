#Stage, #Stage *
    position: absolute
    user-select: none
    -moz-user-select: none
    -webkit-user-select: none
    -o-user-select: none
    -ms-user-select: none
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    -webkit-text-size-adjust: none
    line-height: 1em

#Stage
    font-family: 'BurbankBigCondensed-Black'
    color: #fff
    .UIWarpPrompt
        .Background > .Background
            background: url(../images/pink-gradient.jpg)

#Stage br, #Stage span, #Stage b, #Stage strong
    position: relative

.fr, .ru
    #Stage
        .UIWarpPrompt .Text
            white-space: nowrap
            font-size: 40px !important
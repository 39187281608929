//This CSS resource incorporates links to web font and font software which is the valuable copyrighted property of House Industries and/or its suppliers.
  You expressly agree to not copy, install, redistribute, convert, modify, or reverse engineer this font software or
  the fonts rendered thereby. Please contact House Industries with any questions regarding Web Fonts: http://www.houseind.com.
@font-face
    font-family: 'BurbankBigCondensed-Black'
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankBigCondensed-Black.eot')
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankBigCondensed-Black.eot#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/Burbank/BurbankBigCondensed-Black.woff') format('woff'), url('~epic-fortnite-styles/fonts/Burbank/BurbankBigCondensed-Black.svg') format('svg')
    font-weight: normal
    font-style: normal
    font-stretch: normal

@font-face
    font-family: 'BurbankSmall-Black'
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankSmall-Black.eot')
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankSmall-Black.eot#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/Burbank/BurbankSmall-Black.woff') format('woff'), url('~epic-fortnite-styles/fonts/Burbank/BurbankSmall-Black.svg') format('svg')
    font-weight: normal
    font-style: normal
    font-stretch: normal

@font-face
    font-family: 'BurbankBigRegular-Black'
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankBigRegular-Black.eot')
    src: url('~epic-fortnite-styles/fonts/Burbank/BurbankBigRegular-Black.eot#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/Burbank/BurbankBigRegular-Black.woff') format('woff'), url('~epic-fortnite-styles/fonts/Burbank/BurbankBigRegular-Black.woff2') format('woff2'), url('~epic-fortnite-styles/fonts/Burbank/BurbankBigRegular-Black.svg') format('svg')
    font-weight: normal
    font-style: normal
    font-stretch: normal

@font-face
    font-display: swap
    font-family: "BurbankSmall"
    font-style: normal
    font-weight: 600
    src: url("~epic-fortnite-styles/fonts/Burbank/BurbankSmall-Bold.woff2") format("woff2")

@font-face
    font-display: swap
    font-family: "BurbankSmall"
    font-style: italic
    font-weight: 600
    src: url("~epic-fortnite-styles/fonts/Burbank/BurbankSmall-BoldItalic.woff2") format("woff2")

//This CSS resource incorporates links to web font and font software which is the valuable copyrighted property of House Industries and/or its suppliers.
  You expressly agree to not copy, install, redistribute, convert, modify, or reverse engineer this font software or
  the fonts rendered thereby. Please contact House Industries with any questions regarding Web Fonts: http://www.houseind.com.

@font-face
    font-family: 'OpenSans'
    font-weight: 200
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 300
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 400
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Regular.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Regular.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Regular.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Regular.svg#open_sansregular') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Semibold.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Semibold.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Semibold.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Semibold.svg#open_sanssemibold') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 600
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 800
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg')
@font-face
    font-family: 'OpenSans'
    font-weight: 900
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-ExtraBold.eot')
    src: url('~epic-fortnite-styles/fonts/openSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-ExtraBold.woff') format('woff'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-ExtraBold.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/openSans/OpenSans-ExtraBold.svg#open_sansExtraBold') format('svg')

//fonts for KO
@font-face
    font-family: 'aERIN'
    font-weight: 300
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_L.eot')
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_L.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_L.woff') format('woff'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_L.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_L.svg') format('svg')

@font-face
    font-family: 'aERIN'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_M.eot')
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_M.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_M.woff') format('woff'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_M.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_M.svg') format('svg')

@font-face
    font-family: 'aERIN'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_B.eot')
    src: url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_B.eot?#iefix') format('embedded-opentype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_B.woff') format('woff'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_B.ttf') format('truetype'), url('~epic-fortnite-styles/fonts/KO_ERIN/aERIN_B.svg') format('svg')

@font-face
    font-family: 'NotoSansCJKkr'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansKo/NotoSansCJKkr-Regular.otf') format('opentype')

@font-face
    font-family: 'NotoSansCJKkr'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansKo/NotoSansCJKkr-Bold.otf') format('opentype')

//Japanese Fonts
@font-face
    font-family: 'Tazugane'
    font-weight: bold
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/Tazugane/Tazugane-Bold.woff2') format('woff2')

//Japanese Body Font
@font-face
    font-family: 'NotoSansCJKjp'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansJP/NotoSansCJKjp-Regular.otf') format('opentype')

@font-face
    font-family: 'NotoSansCJKjp'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansJP/NotoSansCJKjp-Bold.otf') format('opentype')

//Arabic Fonts
@font-face
    font-family: 'NotoNaskhArabic'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansAr/NotoNaskhArabic-Regular.ttf') format('truetype')

@font-face
    font-family: 'NotoNaskhArabic'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansAr/NotoNaskhArabic-Bold.ttf') format('truetype')

@font-face
    font-family: 'NotoNaskhArabicUI'
    font-weight: 500
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansAr/NotoNaskhArabicUI-Regular.ttf') format('truetype')

@font-face
    font-family: 'NotoNaskhArabicUI'
    font-weight: 700
    font-style: normal
    src: url('~epic-fortnite-styles/fonts/notoSans/NotoSansAr/NotoNaskhArabicUI-Bold.ttf') format('truetype')

@import './mixins.sass'

html
    width: 100%
    height: 100%
    font-size: 16px

body
    width: 100%
    height: 100%
    background-color: #2a2a2a
    font-family: OpenSans, sans-serif
    font-size: 16px
    text-rendering: optimizeLegibility

.align-bottom
    position: absolute
    bottom: 1em
    left: 0
    right: 0
    margin: 0 1em 0 0
    color: #ffffff
    text-align: right
    h3
        @include respond-max-width(sm)
            font-size: 1em
    @include respond-max-width(sm)
        margin: 0

.col-centered
    float: none
    margin: 0 auto

.ellipsis
    +ellipsis

.embed-responsive
    position: relative
    display: block
    height: 0
    padding: 0
    overflow: hidden

.embed-responsive-16by9
    padding-bottom: 56.25%

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    border: 0
